import {KundeDialogState} from '../states/kunde-dialog.state';
import {KundeDialogActions} from '../actions/kunde-dialog.actions';
import {createReducer, on} from '@ngrx/store';


export const initialKundeDialogState: KundeDialogState = {
  isOpen: false,
  kunde: {},
  addToInvoice: undefined,
};

export const kundeDialogReducer = createReducer(
  initialKundeDialogState,

  on(
    KundeDialogActions.open,
    (state, action) => ({
      ...state,
      isOpen: true,
      kunde: action.kunde || {},
      addToInvoice: action.addToInvoice ?? undefined,
    }),
  ),

  on(
    KundeDialogActions.changeKunde,
    (state, {kunde}) => {
      return {
        ...state,
        kunde,
      };
    },
  ),

  on(
    KundeDialogActions.close,
    () => ({
      ...initialKundeDialogState,
    }),
  ),

  on(
    KundeDialogActions.assignNextKundennummer,
    (state, {nummer}) => {
      return {
        ...state,
        kunde: {
          ...state.kunde,
          kundennummer: state.kunde.kundennummer === undefined ? nummer : state.kunde.kundennummer,
        },
      };
    },
  ),

);
