import {KundeLeistungsempfaengerState} from '../states/kunde-leistungsempfaenger.state';
import {createReducer, on} from '@ngrx/store';
import {KundeLeistungsempfaengerActions} from '../actions/kunde-leistungsempfaenger.actions';
import {FakturierungsbelegFormActions} from '../actions/fakturierungsbeleg-form.actions';


export const initialKundeLeistungsempfaengerState: KundeLeistungsempfaengerState = {
  displayedIds: [],
  pageableDto: {
    size: 10,
    number: 0,
  },
  readMoreKunden: false,
};

export const kundeLeistungsempfaengerReducer = createReducer(
  initialKundeLeistungsempfaengerState,

  on(
    KundeLeistungsempfaengerActions.setDisplayedIds,
    (state, {kundeDtos}) => ({
      ...state,
      displayedIds: kundeDtos.map(kundeDto => kundeDto.id!),
    }),
  ),

  on(
    KundeLeistungsempfaengerActions.addDisplayedIds,
    (state, {kundeDtos}) => ({
      ...state,
      displayedIds: [
        ...state.displayedIds,
        ...kundeDtos.map(kundeDto => kundeDto.id!),
      ],
    }),
  ),

  on(
    KundeLeistungsempfaengerActions.clearDisplayedKunden,
    (state) => ({
      ...state,
      displayedIds: [],
    }),
  ),

  on(FakturierungsbelegFormActions.setLeistungsempfaenger, (state, {kundeId}) => {
    let updatedDisplayedIds = [...state.displayedIds];
    if (!updatedDisplayedIds.includes(kundeId!)) {
      updatedDisplayedIds = [
        ...updatedDisplayedIds,
        kundeId!,
      ];
    }

    return {
      ...state,
      displayedIds: updatedDisplayedIds,
    };
  }),

  on(FakturierungsbelegFormActions.changeLeistungsempfaengerMode, (state, {mode}) => {
    if (mode === 'display') {
      return {
        ...state,
        readMoreKunden: false,
        pageableDto: {
          ...initialKundeLeistungsempfaengerState.pageableDto,
        },
      };
    }

    return {
      ...state,
    };
  }),

  on(KundeLeistungsempfaengerActions.triggerReadMoreKunden, (state) => {
    return {
      ...state,
      readMoreKunden: true,
      pageableDto: {
        ...state.pageableDto,
        number: (state.pageableDto.number || 0) + 1,
      },
    };
  }),
);
