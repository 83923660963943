import {createAction, props} from '@ngrx/store';
import {TableSettings} from '../../interfaces/table-setting.interface';
import {PageableDTO, ProduktDTO} from '../../openapi/fakturierung-openapi';
import {MappedHttpErrorResponse} from '@adnova/jf-ng-components';


export class ProduktTableActions {

  public static readProdukte = createAction(
    '[Produkte Table] Read produkte.',
    props<{
      betriebId: string,
      pageableDto: PageableDTO,
    }>(),
  );

  public static setDisplayedIds = createAction(
    '[Produkte Effects] Set produkte ids that should be displayed at produkt-table component.',
    props<{
      produktDtos: ProduktDTO[],
    }>(),
  );

  public static clearDisplayedProdukte = createAction(
    '[Produkte Effects] Clear produkte that should be displayed at produkt-table component.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static updateSortingEnum = createAction(
    '[Produkt Table] Updates the sorting enum.',
    props<{
      sorting: any[],
    }>(),
  );

  public static changeTableSettings = createAction(
    '[Produkt Table] Changed table settings.',
    props<{ tableSettings: TableSettings }>(),
  );

  public static changePage = createAction(
    '[Produkt Table] Changed pageable.',
    props<{ pageableDto: PageableDTO }>(),
  );

  public static updateSearchValue = createAction(
    '[Produkt Table] Changed search value',
    props<{ searchValue: string[] }>(),
  );

}
