import {createReducer, on} from '@ngrx/store';
import {ProduktColumn, ProduktTableState} from '../states/produkt-table.state';
import {ProduktTableActions} from '../actions/produkt-table.actions';
import {produktIdsMock} from '../../mocks/data/produkt-entities.mock';
import {ProduktEntitiesActions} from '../actions/produkt-entities.actions';


export const initialProduktTableState: ProduktTableState = {
  // FIXME: Passende Diplayed-Ids laden
  displayedIds: produktIdsMock,
  tableSettings: {
    _key: 'produkt-table-settings-2024-06-26',
    _columns: [
      {
        id: ProduktColumn.Nummer,
        name: 'Nummer',
        isActive: true,
        width: 6,
      }, {
        id: ProduktColumn.Bezeichnung,
        name: 'Produkt',
        isActive: true,
        width: 'allocate',
        overflow: 'ellipsis',
      }, {
        id: ProduktColumn.Preis,
        name: 'Preis (Netto)',
        isActive: true,
        alignCell: 'right',
        alignHeader: 'right',
        width: 10,
      }, {
        id: ProduktColumn.USt,
        name: 'USt',
        isActive: true,
        alignCell: 'right',
        alignHeader: 'right',
        width: 5,
      }, {
        id: ProduktColumn.Actions,
        name: '',
        isActive: true,
        sortable: false,
        lastCol: true,
      },
    ],
    _sortDirs: [],
    _sortParams: [],
  },
  pageableDto: {
    size: 10,
    number: 0,
  },
  totalPages: 0,
};

export const produktTableReducer = createReducer(
  initialProduktTableState,

  on(ProduktTableActions.setDisplayedIds, (state, {produktDtos}) => ({
      ...state,
      displayedIds: produktDtos.map(produktDto => produktDto.id),
    }),
  ),

  on(ProduktTableActions.clearDisplayedProdukte, (state) => ({
      ...state,
      displayedIds: [],
    }),
  ),

  on(
    ProduktTableActions.changeTableSettings,
    (state, action) => ({
      ...state,
      tableSettings: action.tableSettings,
    })
  ),

  on(
    ProduktTableActions.changePage,
    (state, action) => ({
      ...state,
      pageableDto: {
        ...state.pageableDto,
        ...action.pageableDto,
      },
    })
  ),

  on(
    ProduktEntitiesActions.countProduktePagesSuccess,
    (state, {totalPages}) => ({
      ...state,
      totalPages,
    })
  ),
);
