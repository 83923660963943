import {
  AdressfeldDTO,
  BelegBetriebDTO,
  BelegDTO,
  BelegEinstellungDTO,
  BelegKundeDTO,
  BelegStatusDTO
} from '../../openapi/fakturierung-openapi';


const adressfeldMock: AdressfeldDTO = {
  zeilen: ['Musterstraße 1', '12345 Musterstadt']
};

const belegKundeDTO: BelegKundeDTO = {
  nummer: 10000,
  adressfeld: adressfeldMock,
};

const belegEinstellungDTO: BelegEinstellungDTO = {
  bankkonto: {
    id: 'bankkonto-1',
    iban: 'DE89370400440532013000',
    bic: 'COBADEFFXXX',
    kontoinhaber: 'Max Mustermann',
    bankbezeichnung: 'Commerzbank'
  },
  kontakt: {
    vorname: 'Max',
    nachname: 'Mustermann',
    emailAdresse: 'max@mustermann.de',
    telefonnummer: '+491234567890',
    internetadresse: 'www.mustermann.de'
  }
};

const belegStatusDTO: BelegStatusDTO = {
  offen: true,
  festgeschrieben: false,
  storniert: false,
};

const belegBetriebDTO: BelegBetriebDTO = {
  steuernummer: '123456789',
  ustIdNummer: 'DE123456789',
  absenderZeile: 'Unternehmen Muster GmbH',
  adresseFusszeile: ['Musterstraße 1', '12345 Musterstadt', 'Telefon: +49 123 456789']
};

export const fakturierungsbelegMock1: BelegDTO = {
  id: '1',
  betriebId: 'd98ec4da-47e4-4ae0-accf-d5c0e2758317',
  nummer: 1,
  datum: '12.10.2024',
  bruttoBetragssumme: 1000,
  nettoBetragssumme: 800,
  vorlaufzeile: 'Danke, danke, danke. Wir erlauben uns, die nachfolgenden Dinge zu berechnen:',
  kundeId: '1',
  kunde: belegKundeDTO,
  einstellungId: '1',
  einstellung: belegEinstellungDTO,
  status: belegStatusDTO,
  betrieb: belegBetriebDTO
};

export const fakturierungsbelegMock2: BelegDTO = {
  id: '2',
  betriebId: 'd98ec4da-47e4-4ae0-accf-d5c0e2758317',
  nummer: 2,
  datum: '01.01.2021',
  bruttoBetragssumme: 750,
  nettoBetragssumme: 630,
  vorlaufzeile: 'Dies ist ein Begrüßungstext',
  kundeId: '29',
  kunde: belegKundeDTO,
  einstellungId: '1',
  einstellung: belegEinstellungDTO,
  status: {...belegStatusDTO, offen: false, festgeschrieben: true},
  betrieb: belegBetriebDTO
};

export const fakturierungsbelegMock3: BelegDTO = {
  id: '3',
  betriebId: 'd98ec4da-47e4-4ae0-accf-d5c0e2758317',
  nummer: 3,
  datum: '22.05.2023',
  bruttoBetragssumme: 850,
  nettoBetragssumme: 700,
  vorlaufzeile: 'Sieh zu, dass du bezahlst Junge!',
  kundeId: '10',
  kunde: belegKundeDTO,
  einstellungId: '1',
  einstellung: belegEinstellungDTO,
  status: {...belegStatusDTO, offen: false, festgeschrieben: true, storniert: false},
  betrieb: belegBetriebDTO
};

export const fakturierungsbelegeMock: BelegDTO[] = [
  fakturierungsbelegMock1,
  fakturierungsbelegMock2,
  fakturierungsbelegMock3
];

export const fakturierungsbelegEntitiesMock = {
  '1': fakturierungsbelegMock1,
  '2': fakturierungsbelegMock2,
  '3': fakturierungsbelegMock3,
};

export const fakturierungsbelegIdsMock = Object.keys(fakturierungsbelegEntitiesMock);
