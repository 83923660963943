import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NGXLogger} from 'ngx-logger';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FakturierungsbelegFormActions} from '../actions/fakturierungsbeleg-form.actions';
import {concatMap, Observable, of, tap, withLatestFrom} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {SentryActions} from '../actions/sentry.actions';
import {FakturierungsbelegFormSelectors} from '../selectors/fakturierungsbeleg-form.selectors';
import {AppState} from '../states/app.state';
import {Store} from '@ngrx/store';


@Injectable()
export class FakturierungsbelegFormEffects {

  constructor(
    private actions$: Actions,
    private logger: NGXLogger,
    private snackbar: MatSnackBar,
    private httpClient: HttpClient,
    private store: Store<AppState>) {
  }


  readonly updateFormValidity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        FakturierungsbelegFormActions.removePositionByNumber,
        FakturierungsbelegFormActions.addPosition,
        FakturierungsbelegFormActions.updateGesamtBruttoBetrag,
        FakturierungsbelegFormActions.setLeistungsempfaenger
      ),
      map(() => FakturierungsbelegFormActions.updateFormValidity())
    )
  );

  readonly saveLogo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FakturierungsbelegFormActions.saveLogo),
      concatMap(({betriebId, logoUrl}) =>
        this.httpClient.get(logoUrl, {responseType: 'blob'}).pipe(
          withLatestFrom(this.store.select(FakturierungsbelegFormSelectors.logoUrl)),
          concatMap(([blob, currentLogoUrl]) => {
            const saveOrUpdateLogo$ = currentLogoUrl ? this.updateLogo(betriebId, blob) : this.saveLogo(betriebId, blob);
            return saveOrUpdateLogo$.pipe(
              map(() => FakturierungsbelegFormActions.saveLogoSuccess({logoUrl})),
              catchError((error) =>
                of(FakturierungsbelegFormActions.saveLogoFailure({error}))
              )
            );
          })
        )
      )
    )
  );

  readonly saveLogoFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegFormActions.saveLogoFailure),
      tap(action => {
        let errorMsg = '';
        switch (action.error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Speichern des Logos.';
            break;
          }
          case 404 : {
            errorMsg = 'Fehler beim Speichern des Logos. Inhaber nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Speichern des Logos. Bitte probiere es später erneut.';
          }
        }
        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );
        return SentryActions.captureException({
          error: action.error,
          extras: {
            errorMsg,
          },
        });
      })
    )
  );

  readonly getLogo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FakturierungsbelegFormActions.getLogo),
      concatMap(({betriebId}) =>
        this.getLogo(betriebId).pipe(
          map((binaryData) => {
            const logoUrl = binaryData?.logoAsBinary ? window.URL.createObjectURL(binaryData.logoAsBinary) : null;
            return FakturierungsbelegFormActions.getLogoSuccess({logoUrl});
          }),
          catchError((error) => {
            return of(FakturierungsbelegFormActions.getLogoFailure({error}));
          })
        )
      )
    )
  );

  readonly getLogoFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegFormActions.getLogoFailure),
      tap(action => {
        let errorMsg = '';
        switch (action.error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Abrufen des Logos.';
            break;
          }
          case 404 : {
            errorMsg = 'Fehler beim Abrufen des Logos. Inhaber nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Abrufen des Logos. Bitte probiere es später erneut.';
          }
        }
        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );
        return SentryActions.captureException({
          error: action.error,
          extras: {
            errorMsg,
          },
        });
      })
    )
  );

  readonly deleteLogo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FakturierungsbelegFormActions.deleteLogo),
      concatMap(({betriebId}) =>
        this.deleteLogo(betriebId).pipe(
          map(() => {
            return FakturierungsbelegFormActions.releaseLogoFromMemory({});
          }),
          catchError(error => {
            return of(FakturierungsbelegFormActions.deleteLogoFailure({error}));
          })
        )
      )
    )
  );

  readonly deleteLogoFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegFormActions.deleteLogoFailure),
      tap(action => {
        let errorMsg = '';
        switch (action.error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Löschen des Logos.';
            break;
          }
          case 404 : {
            errorMsg = 'Fehler beim Löschen des Logos. Inhaber nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Löschen des Logos. Bitte probiere es später erneut.';
          }
        }
        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );
        return SentryActions.captureException({
          error: action.error,
          extras: {
            errorMsg,
          },
        });
      })
    )
  );

  readonly releaseLogoFromMemory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FakturierungsbelegFormActions.releaseLogoFromMemory),
      withLatestFrom(this.store.select(FakturierungsbelegFormSelectors.logoUrl)),
      tap(([action, storeLogoUrl]) => {
        const logoUrl = action.logoUrl || storeLogoUrl;
        if (logoUrl) {
          window.URL.revokeObjectURL(logoUrl);
        }
      })
    ), {dispatch: false}
  );


  // INFO : Placeholder method, until the backend is implemented.
  private saveLogo(inhaberId: string, body: Blob): Observable<boolean> {
    if (inhaberId === null || inhaberId === undefined) {
      throw new Error('Required parameter inhaberId was null or undefined when calling saveLogo.');
    }
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling saveLogo.');
    }

    console.log('Temporary solution: Simulating successful save of logo.');
    return of(true);  // Simulate successful response
  }

  // INFO : Placeholder method, until the backend is implemented.
  private updateLogo(inhaberId: string, body: Blob): Observable<boolean> {
    if (inhaberId === null || inhaberId === undefined) {
      throw new Error('Required parameter inhaberId was null or undefined when calling updateLogo.');
    }
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateLogo.');
    }

    console.log('Temporary solution: Simulating successful update of logo.');
    return of(true);  // Simulate successful response
  }

  // INFO : Placeholder method, until the backend is implemented.
  private deleteLogo(inhaberId: string): Observable<void> {
    if (!inhaberId) {
      throw new Error('Required parameter inhaberId was null or undefined when calling deleteLogo.');
    }

    console.log('Temporary solution: Simulating successful logo deletion.');
    return of(undefined);
  }

  // INFO : Placeholder method, until the backend is implemented.
  private getLogo(inhaberId: string): Observable<any> {
    if (!inhaberId) {
      throw new Error('Required parameter inhaberId was null or undefined when calling getLogo.');
    }

    console.log('Temporary solution: Simulating successful logo fetching.');
    return of(null);
  }

}
