import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './auth/auth.guard';
import {BetriebsauswahlPageComponent} from './modules/pages/betriebsauswahl/betriebsauswahl.page.component';
import {AppNavigationComponent} from './modules/pages/app-navigation/app-navigation.component';
import {NavigationLink} from './modules/pages/app-navigation/navigation-link.interface';
import {ButtonVariant, RouterActions} from '@adnova/jf-ng-components';
import {ProduktDialogActions} from './store/actions/produkt-dialog.actions';
import {KundeDialogActions} from './store/actions/kunde-dialog.actions';
import {ProduktDTO} from './openapi/fakturierung-openapi';


/** INFO:
 * Die BetriebsauswahlPage muss über der Ebene der Routes liegen,
 * in der Sie eingebunden sein soll, um nicht bei jedem Navigationsschritt neu geladen zu werden.
 *
 */

const routes: Routes = [
  {
    /*
    * INFO: Alle Routen, die unterhalb dieses Eintrags liegen, verwenden die Betriebsauswahl.
    * Da dafür die Inhaber geladen werden müssen, werden sie außerdem durch den AuthGuard geschützt.
    * Alle Seiten, die die Betriebsauswahl anzeigen, sollen außerdem den NavigationService verwenden und potenziell
    * an der Betriebssynchronisation teilnehmen.
    * Diese Einstellungen können pro Child-Route individuell überschrieben werden.
    *
    */
    path: '',
    component: BetriebsauswahlPageComponent,
    canActivate: [AuthGuard],
    data: {
      redirectAllowed: true, // INFO: Automatische Betriebssynchronisation aktivieren.
      useInhaberService: true, // INFO: NavigationService verwenden. Inhaber werden geladen und Fallback-ID wird automatisch gesetzt.
      navigationLinks: [ // INFO: Navigationselemente für die App-Navigation oberhalb des Contents.
        {
          label: 'Rechnungen',
          iconName: 'fileDoc',
          route: '/fakturierungsbelege',
        },
        {
          label: 'Kontakte',
          iconName: 'user',
          route: '/kunden',
        },
        {
          label: 'Produkte',
          iconName: 'stack',
          route: '/produkte',
        },
      ] as NavigationLink[],
    },
    children: [
      {
        /*
        * INFO: Alle Routen, die unterhalb dieses Eintrags liegen, verwenden die App-Navigation.
        * Dabei handelt es sich um eine Navigationsleiste, die sich oberhalb des Inhalts befindet.
        */
        path: '',
        component: AppNavigationComponent,
        children: [
          {
            path: '',
            pathMatch: 'prefix',
            redirectTo: 'fakturierungsbelege/inhaber/',
          },
          {
            path: 'fakturierungsbelege',
            children: [
              {
                path: 'inhaber',
                children: [
                  {
                    path: ':inhaber',
                    children: [
                      {
                        path: '',
                        loadComponent: () => import('./modules/routes/fakturierungsbelege/fakturierungsbelege.component').then(m => m.FakturierungsbelegeComponent),
                        data: {
                          buttonLabel: 'Neue Rechnung', // INFO: Neue-Rechnung-Button anzeigen.
                          buttonAction: RouterActions.navigateByUrl({url: '/fakturierungsbelege/inhaber/:inhaber/edit/fakturierungsbeleg'}), // INFO: Navigiere zum Erstellen-Formular
                          buttonVariant: 'primary' as ButtonVariant, // INFO: Variante für den Neue-Rechnung-Button.
                        },
                      },
                      {
                        path: 'edit/fakturierungsbeleg',
                        data: {
                          redirectAllowed: false, // INFO: Deaktivieren der automatischen Betriebssynchronisation.
                          navigationLinks: [] as NavigationLink[], // INFO: Oben keine Navigationselemente anzeigen.
                          buttonLabel: 'Zur Übersicht', // INFO: Zur-Übersicht-Button anzeigen.
                          buttonAction: RouterActions.navigateByUrl({url: '/fakturierungsbelege/inhaber/:inhaber'}), // INFO: Navigiere zur Übersicht
                          buttonIcon: 'arrowLeft', // INFO: Icon für den Zur-Übersicht-Button.
                          buttonVariant: 'ghost', // INFO: Variante für den Zur-Übersicht-Button.
                        },
                        loadComponent: () => import('./modules/routes/fakturierungsbelege-edit/fakturierungsbelege-edit.component').then(c => c.FakturierungsbelegeEditComponent),
                      },
                      {
                        path: 'edit/fakturierungsbeleg/:fakturierungsbeleg',
                        data: {
                          redirectAllowed: false, // INFO: Deaktivieren der automatischen Betriebssynchronisation.
                          navigationLinks: [] as NavigationLink[], // INFO: Oben keine Navigationselemente anzeigen.
                          buttonLabel: 'Zur Übersicht', // INFO: Zur-Übersicht-Button anzeigen.
                          buttonAction: RouterActions.navigateByUrl({url: '/fakturierungsbelege/inhaber/:inhaber'}), // INFO: Navigiere zur Übersicht
                          buttonIcon: 'arrowLeft', // INFO: Icon für den Zur-Übersicht-Button.
                          buttonVariant: 'ghost', // INFO: Variante für den Zur-Übersicht-Button.
                        },
                        loadComponent: () => import('./modules/routes/fakturierungsbelege-edit/fakturierungsbelege-edit.component').then(c => c.FakturierungsbelegeEditComponent),
                      },
                      {
                        path: 'preview/fakturierungsbeleg/:fakturierungsbeleg',
                        data: {
                          redirectAllowed: false, // INFO: Deaktivieren der automatischen Betriebssynchronisation.
                          navigationLinks: [] as NavigationLink[], // INFO: Oben keine Navigationselemente anzeigen.
                          buttonLabel: 'Zur Übersicht', // INFO: Zur-Übersicht-Button anzeigen.
                          buttonAction: RouterActions.navigateByUrl({url: '/fakturierungsbelege/inhaber/:inhaber'}), // INFO: Navigiere zur Übersicht
                          buttonIcon: 'arrowLeft', // INFO: Icon für den Zur-Übersicht-Button.
                          buttonVariant: 'ghost', // INFO: Variante für den Zur-Übersicht-Button.
                        },
                        loadComponent: () => import('./modules/routes/fakturierungsbelege-preview/fakturierungsbelege-preview.component').then(c => c.FakturierungsbelegePreviewComponent),
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: 'kunden',
            children: [
              {
                path: 'inhaber',
                children: [
                  {
                    path: ':inhaber',
                    children: [
                      {
                        path: '',
                        loadComponent: () => import('./modules/routes/kunde/kunde.component').then(m => m.KundeComponent),
                        data: {
                          buttonLabel: 'Neuer Kontakt', // INFO: Neue-Rechnung-Button anzeigen.
                          buttonAction: KundeDialogActions.open({kunde: undefined}), // INFO: Öffnen des Dialogs zum Erstellen-Formular
                          buttonVariant: 'primary' as ButtonVariant, // INFO: Variante für den Neue-Rechnung-Button.
                        },
                      },
                    ]
                  },
                ],
              },
            ],
          },
          {
            path: 'produkte',
            children: [
              {
                path: 'inhaber',
                children: [
                  {
                    path: ':inhaber',
                    children: [
                      {
                        path: '',
                        loadComponent: () => import('./modules/routes/produkte/produkte.component').then(m => m.ProdukteComponent),
                        data: {
                          buttonLabel: 'Neues Produkt', // INFO: Neue-Rechnung-Button anzeigen.
                          buttonAction: ProduktDialogActions.open({produkt: {} as ProduktDTO}), // INFO: Öffnen des Dialogs zum Erstellen-Formular
                          buttonVariant: 'primary' as ButtonVariant, // INFO: Variante für den Neue-Rechnung-Button.
                        },
                      },
                    ]
                  },
                ],
              },
            ],
          },
        ],
      },
    ]
  },
  {
    path: '',
    component: AppNavigationComponent,
    children: [
      {
        path: 'legal/impressum',
        data: {
          redirectAllowed: false, // INFO: Deaktivieren der automatischen Betriebssynchronisation.
          navigationLinks: [] as NavigationLink[], // INFO: Oben keine Navigationselemente anzeigen.
          buttonLabel: 'Zur Übersicht', // INFO: Zur-Übersicht-Button anzeigen.
          buttonAction: RouterActions.navigateByUrl({url: '/fakturierungsbelege/inhaber/:inhaber'}), // INFO: Navigiere zur Übersicht
          buttonIcon: 'arrowLeft', // INFO: Icon für den Zur-Übersicht-Button.
          buttonVariant: 'ghost' // INFO: Variante für den Zur-Übersicht-Button.
        },
        loadComponent: () => import('./modules/routes/legal/impressum/impressum.component').then(m => m.ImpressumComponent),
      },
    ]
  },
  {
    path: '**',
    redirectTo: '/fakturierungsbelege'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      scrollPositionRestoration: 'top',
      preloadingStrategy: PreloadAllModules
    }
  )],
  providers: [],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
