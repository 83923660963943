import {createAction, props} from '@ngrx/store';
import {PageableDTO, ProduktDTO} from '../../openapi/fakturierung-openapi';
import {MappedHttpErrorResponse} from '@adnova/jf-ng-components';


export class AddPositionActions {

  public static readProdukte = createAction(
    '[Fakturierungsbeleg-Form] Read Produkte at add-position component.',
    props<{
      betriebId: string,
      pageableDto: PageableDTO,
    }>(),
  );

  public static setDisplayedIds = createAction(
    '[Fakturierungsbeleg Entities] Set produkt ids that should be displayed at add-position component.',
    props<{
      produktDtos: ProduktDTO[],
    }>(),
  );

  public static clearDisplayedProdukte = createAction(
    '[Fakturierungsbeleg Entities] Clear produkte that should be displayed at add-position component.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static updateFilter = createAction(
    '[Fakturierungsbeleg-Form] Updates the filter at add-position component.',
    props<{
      filter: string[],
    }>(),
  );

  public static loadMoreOptions = createAction(
    '[Fakturierungsbeleg-Form] Updates the pageableDto to load more options add-position component.',
  );

}
